const mobileFullscreen = 'xs:100vw sm:100vw';
const desktopFullscreen = 'md:100vw lg:100vw xl:100vw xxl:100vw xxxl:100vw';
const fullscreen = `${mobileFullscreen} ${desktopFullscreen}`;
const extended = `xs:200vw sm:200vw ${desktopFullscreen}`;
const halfScreen = 'xs:50vw sm:50vw md:50vw lg:50vw xl:50vw xxl:50vw xxxl:50vw';
const adaptiveHalfScreen = `${mobileFullscreen} md:50vw lg:50vw xl:50vw xxl:50vw xxxl:50vw`;
const gridCard = 'xs:50vw sm:50vw md:50vw lg:50vw xl:25vw xxl:25vw xxxl:25vw';
const blogSmall = `${mobileFullscreen} md:50vw lg:50vw xl:25vw xxl:25vw xxxl:25vw`;
const blogLarge = `${mobileFullscreen} md:50vw lg:50vw xl:50vw xxl:50vw xxxl:50vw`;
const blogInCatalog = `${mobileFullscreen} md:50vw lg:50vw xl:25vw xxl:25vw xxxl:25vw`;
const media = 'xs:100vw sm:100vw md:416';
const newsSmall = 'xs:100vw sm:100vw md:337';
const newsLarge = 'xs:100vw sm:100vw md:574';

export const IMAGE_SIZES = {
  mobileFullscreen,
  desktopFullscreen,
  fullscreen,
  extended,
  halfScreen,
  adaptiveHalfScreen,
  gridCard,
  blogSmall,
  blogLarge,
  blogInCatalog,
  media,
  newsSmall,
  newsLarge,
};
