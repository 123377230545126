<script lang="ts" setup>
import type { BaseScroll } from '#components';
import ogImage from '~/assets/images/og_image.png';

const { t, locale } = useI18n();

const config = useRuntimeConfig();
const commonStore = useCommonStore();
const userStore = useUserStore();
const modalStore = useModalStore();

const isCypress = useCookie<boolean>('cypress');

const scroll = ref<ComponentPublicInstance<typeof BaseScroll>>();

onServerPrefetch(() => commonStore.fetchCommon());

onMounted(() => {
  userStore.fetchUser();
});

useMetrics();
useSchemaOrg([
  defineWebSite({
    name: `Don't Touch My Skin`,
    logo: '/android-chrome-512x512.png',
  }),
  defineWebPage(),
  defineLocalBusiness({
    name: `Don't Touch My Skin`,
    address: {
      streetAddress: t('company.address.value'),
      addressLocality: t('company.address.city'),
      addressRegion: 'Moscow',
      postalCode: '125047',
      addressCountry: 'RU',
    },
    image: '/android-chrome-512x512.png',
  }),
]);
</script>

<template>
  <Html :lang="locale" :class="{ disableAnimation: isCypress }" />
  <Head>
    <Title>{{ t('title') }}</Title>
    <Meta name="description" :content="t('description')" />
    <Meta property="og:title" :content="t('title')" />
    <Meta property="og:description" :content="t('description')" />
    <Meta property="og:image" :content="`${config.public.siteUrl}${ogImage}`" />
    <Meta property="og:image:width" content="1200" />
    <Meta property="og:image:height" content="381" />
    <Meta name="twitter:card" content="summary_large_image" />

    <Meta name="viewport" content="width=device-width, initial-scale=1" />
    <Link rel="shortcut icon" href="/favicon.ico" />
    <Link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <Link
      rel="apple-touch-icon"
      sizes="120x120"
      href="/apple-touch-icon-120x120.png"
    />
    <Link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <Link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <Link rel="mask-icon" href="/safari-pinned-tab.svg" color="#121212" />
    <Meta name="theme-color" content="#121212" />
    <Meta name="msapplication-TileColor" content="#121212" />
  </Head>

  <BaseI18nSetup />
  <VitePwaManifest />

  <NuxtLoadingIndicator color="#121212" />
  <NuxtRouteAnnouncer />

  <LayoutMainMenu />

  <BaseScroll
    ref="scroll"
    :disabled="modalStore.scrollDisabled"
    viewport-class="app__viewport global-scroll-viewport"
  >
    <NuxtPage />
  </BaseScroll>

  <ClientOnly>
    <LazyProductCartModal />
    <LazyBaseMessageModalRoot />
    <LazyBaseConfirmationModal />
  </ClientOnly>

  <div ref="modalContainer" id="modals" />
</template>

<i18n src="~/locales/company.yaml" />
<i18n>
ru:
  title: DON'T TOUCH MY SKIN — эффективная и комфортная косметика с активными ингредиентами
  description: Разрабатывая продукты Don’t Touch My Skin, мы всегда стремимся к безопасности, комфорту и здоровью. Нам
    важно, чтобы вам было комфортно в своем теле, со своей кожей, а косметика была приятным и полезным дополнением.

en:
  title: DON'T TOUCH MY SKIN — effective and comfortable cosmetics with active ingredients
  description: When developing Don’t Touch My Skin products, we always strive for safety, comfort and health. It is
    important for us that you feel comfortable in your body, with your skin, and cosmetics are a pleasant and useful
    addition.
</i18n>

<style lang="scss" src="@/assets/styles/components.scss" />
<style lang="scss">
.app {
  &__viewport {
    height: 100dvh;
  }
}
</style>
<style lang="scss">
@font-face {
  font-family: 'PP Neue Montreal';
  src: url('@/assets/fonts/PPNeueMontreal-Variable.ttf') format('truetype');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('@/assets/fonts/PPNeueMontreal-Variable.woff2') format('woff');
  font-weight: 1 999;
  font-display: swap;
  font-style: italic;
}

* {
  box-sizing: border-box;
  font-variation-settings: 'wght' 500;
  font-family: 'PP Neue Montreal', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  overflow: hidden;
}

body {
  margin: 0;
}

strong,
b {
  font-variation-settings: 'wght' 700;
}

.slim-scroll {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #121212;
  }
}

a {
  text-decoration: none;
}

.disableAnimation * {
  animation-duration: 0s !important;
  transition-duration: 0s !important;
}

// TODO: подумать, можно ли адаптировать
// #modals > * {
//   overscroll-behavior: contain;
// }
</style>
