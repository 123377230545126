import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_YDbBDeAQMUKXxbbf1eUnbZoe1b2mwOGJDbqZVSXbXGI from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.15_better-sqlite3@11.9.1_db0@0.3.1__e97c774b38d887f4f7bb7bde6c74f876/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_xYn1iBlZUaBZIVMNmkR1mPjxcjF_JOKOpKK3nLaJ_vk from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.15_better-sqlite3@11.9.1_db0@0.3.1__e97c774b38d887f4f7bb7bde6c74f876/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mHdJ7ePswMuCH4zckgpZztkxs7xMEWjeuQF842Jcq5w from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.15_better-sqlite3@11.9.1_db0@0.3.1__e97c774b38d887f4f7bb7bde6c74f876/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig__QlIgs4p1UgoLAb3AxeMp0K0wLSLcg9rKpHg7JBtqfw from "/app/node_modules/.pnpm/nuxt-site-config@3.1.5_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_xkbfB2ozyx8KiKLtxE_pgs96cWG0BwCjmccVK1dNu9c from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.15_better-sqlite3@11.9.1_db0@0.3.1__e97c774b38d887f4f7bb7bde6c74f876/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ZR9IONpP65j1oy6HU6SL5JLuPRQSzax_7Af1PjjDxyU from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.15_better-sqlite3@11.9.1_db0@0.3.1__e97c774b38d887f4f7bb7bde6c74f876/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_AZcRVg1pUBgDHi5AQBkBtTIBIS0geMXB91h3ibhZS8M from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.15_better-sqlite3@11.9.1_db0@0.3.1__e97c774b38d887f4f7bb7bde6c74f876/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_AhhfSFZ8SClHrEyVqvQfsbSiDCvuUVDvWq2B8kKtUGI from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.15_better-sqlite3@11.9.1_db0@0.3.1__e97c774b38d887f4f7bb7bde6c74f876/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_b_chVXj2lrSAM3ZfXhrC9hH1QKfjxgVcRU76Phwrd5g from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.15_better-sqlite3@11.9.1_db0@0.3.1__e97c774b38d887f4f7bb7bde6c74f876/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_FRL_oFmpbOdj5tSx57yBvWgv4UPZfXaIFJSgtaeWCpw from "/app/node_modules/.pnpm/nuxt-site-config@3.1.5_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/app/.nuxt/pwa-icons-plugin.ts";
import switch_locale_path_ssr_RBzid0muDr5Qay1SbKYLC8hGlOmnnv4Da_BcKM2sx80 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_497193ab634db7ab95856217f2cd219d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_mkoP7qS1g9SNyhRCFiqlEIP_WLuHlv_YBUf_vsmIwLQ from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_497193ab634db7ab95856217f2cd219d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_eVMlPbXFHp2PTdslsTkaHjoqsGsbw51RjsXwiPZAN3s from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_497193ab634db7ab95856217f2cd219d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_nKvso0AA3oZqMKqLdV1r_v6xy8P5JDAs5gh8cP0gxFw from "/app/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_better-sqlite3@11.9.1__ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/plugin.js";
import autosize_xhzljyesIQFkoU_YAcquUaPqh4pFxOCMjmcMJsp_1oo from "/app/plugins/autosize.ts";
import request_9gCQbAiWGlAy9fUYAs4zVRYnxG1YLLfpylHRuF0fPxs from "/app/plugins/request.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/app/plugins/sentry.client.ts";
import pwa_client_Cb0SaaZZ4ypkcFFSFL_2YDGy1DdX4d9hSxjkEsFXE3o from "/app/node_modules/.pnpm/@vite-pwa+nuxt@1.0.0_magicast@0.3.5_vite@6.2.2_@types+node@22.13.15_jiti@2.4.2_sass-emb_5a816a8a9c8abc354d2669d175168a60/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import ssg_detect_3fBL8KTypm9KeFcBql0JRRmLXgbAMOh_TIDyZywdcD4 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_497193ab634db7ab95856217f2cd219d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  revive_payload_client_YDbBDeAQMUKXxbbf1eUnbZoe1b2mwOGJDbqZVSXbXGI,
  unhead_xYn1iBlZUaBZIVMNmkR1mPjxcjF_JOKOpKK3nLaJ_vk,
  router_mHdJ7ePswMuCH4zckgpZztkxs7xMEWjeuQF842Jcq5w,
  _0_siteConfig__QlIgs4p1UgoLAb3AxeMp0K0wLSLcg9rKpHg7JBtqfw,
  payload_client_xkbfB2ozyx8KiKLtxE_pgs96cWG0BwCjmccVK1dNu9c,
  navigation_repaint_client_ZR9IONpP65j1oy6HU6SL5JLuPRQSzax_7Af1PjjDxyU,
  check_outdated_build_client_AZcRVg1pUBgDHi5AQBkBtTIBIS0geMXB91h3ibhZS8M,
  chunk_reload_client_AhhfSFZ8SClHrEyVqvQfsbSiDCvuUVDvWq2B8kKtUGI,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_b_chVXj2lrSAM3ZfXhrC9hH1QKfjxgVcRU76Phwrd5g,
  i18n_FRL_oFmpbOdj5tSx57yBvWgv4UPZfXaIFJSgtaeWCpw,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  switch_locale_path_ssr_RBzid0muDr5Qay1SbKYLC8hGlOmnnv4Da_BcKM2sx80,
  route_locale_detect_mkoP7qS1g9SNyhRCFiqlEIP_WLuHlv_YBUf_vsmIwLQ,
  i18n_eVMlPbXFHp2PTdslsTkaHjoqsGsbw51RjsXwiPZAN3s,
  plugin_nKvso0AA3oZqMKqLdV1r_v6xy8P5JDAs5gh8cP0gxFw,
  autosize_xhzljyesIQFkoU_YAcquUaPqh4pFxOCMjmcMJsp_1oo,
  request_9gCQbAiWGlAy9fUYAs4zVRYnxG1YLLfpylHRuF0fPxs,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  pwa_client_Cb0SaaZZ4ypkcFFSFL_2YDGy1DdX4d9hSxjkEsFXE3o,
  ssg_detect_3fBL8KTypm9KeFcBql0JRRmLXgbAMOh_TIDyZywdcD4
]