import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoBlogDay, LazySvgoBlogNight, LazySvgoError, LazySvgoLandingReshapeCard, LazySvgoLogoAbbreviation, LazySvgoLogoBlog, LazySvgoLogoFull, LazySvgoLogoHorizontal, LazySvgoLogoMission, LazySvgoLogoName, LazySvgoLogoReshape, LazySvgoPlaceholder, LazySvgoSimpleArrow, LazySvgoSimpleAuth, LazySvgoSimpleCaret, LazySvgoSimpleCart, LazySvgoSimpleCheck, LazySvgoSimpleCheckSolid, LazySvgoSimpleChevron, LazySvgoSimpleChevronDown, LazySvgoSimpleChevronLeft, LazySvgoSimpleChevronRight, LazySvgoSimpleClose, LazySvgoSimpleEdit, LazySvgoSimpleInstagram, LazySvgoSimpleMastercard, LazySvgoSimpleMinus, LazySvgoSimpleMir, LazySvgoSimplePlus, LazySvgoSimpleSister, LazySvgoSimpleStar, LazySvgoSimpleTelegram, LazySvgoSimpleUser, LazySvgoSimpleVisa, LazySvgoSimpleVk, LazySvgoSimpleYoutube, LazySvgoSubscribeStep1, LazySvgoSubscribeStep2, LazySvgoSubscribeStep3, LazySvgoSubscribeStep4, LazySvgoSuccess, LazyProseCode, LazyProseEm, LazyProseH1, LazyProseH2, LazyProseH3, LazyProseH4, LazyProseH5, LazyProseH6, LazyProseHr, LazyProseLi, LazyProseP, LazyProsePre, LazyProseScript, LazyProseStrong, LazyProseTbody, LazyProseTd, LazyProseTh, LazyProseThead, LazyProseTr, LazyProseUl } from '#components'
const lazyGlobalComponents = [
  ["SvgoBlogDay", LazySvgoBlogDay],
["SvgoBlogNight", LazySvgoBlogNight],
["SvgoError", LazySvgoError],
["SvgoLandingReshapeCard", LazySvgoLandingReshapeCard],
["SvgoLogoAbbreviation", LazySvgoLogoAbbreviation],
["SvgoLogoBlog", LazySvgoLogoBlog],
["SvgoLogoFull", LazySvgoLogoFull],
["SvgoLogoHorizontal", LazySvgoLogoHorizontal],
["SvgoLogoMission", LazySvgoLogoMission],
["SvgoLogoName", LazySvgoLogoName],
["SvgoLogoReshape", LazySvgoLogoReshape],
["SvgoPlaceholder", LazySvgoPlaceholder],
["SvgoSimpleArrow", LazySvgoSimpleArrow],
["SvgoSimpleAuth", LazySvgoSimpleAuth],
["SvgoSimpleCaret", LazySvgoSimpleCaret],
["SvgoSimpleCart", LazySvgoSimpleCart],
["SvgoSimpleCheck", LazySvgoSimpleCheck],
["SvgoSimpleCheckSolid", LazySvgoSimpleCheckSolid],
["SvgoSimpleChevron", LazySvgoSimpleChevron],
["SvgoSimpleChevronDown", LazySvgoSimpleChevronDown],
["SvgoSimpleChevronLeft", LazySvgoSimpleChevronLeft],
["SvgoSimpleChevronRight", LazySvgoSimpleChevronRight],
["SvgoSimpleClose", LazySvgoSimpleClose],
["SvgoSimpleEdit", LazySvgoSimpleEdit],
["SvgoSimpleInstagram", LazySvgoSimpleInstagram],
["SvgoSimpleMastercard", LazySvgoSimpleMastercard],
["SvgoSimpleMinus", LazySvgoSimpleMinus],
["SvgoSimpleMir", LazySvgoSimpleMir],
["SvgoSimplePlus", LazySvgoSimplePlus],
["SvgoSimpleSister", LazySvgoSimpleSister],
["SvgoSimpleStar", LazySvgoSimpleStar],
["SvgoSimpleTelegram", LazySvgoSimpleTelegram],
["SvgoSimpleUser", LazySvgoSimpleUser],
["SvgoSimpleVisa", LazySvgoSimpleVisa],
["SvgoSimpleVk", LazySvgoSimpleVk],
["SvgoSimpleYoutube", LazySvgoSimpleYoutube],
["SvgoSubscribeStep1", LazySvgoSubscribeStep1],
["SvgoSubscribeStep2", LazySvgoSubscribeStep2],
["SvgoSubscribeStep3", LazySvgoSubscribeStep3],
["SvgoSubscribeStep4", LazySvgoSubscribeStep4],
["SvgoSuccess", LazySvgoSuccess],
["ProseCode", LazyProseCode],
["ProseEm", LazyProseEm],
["ProseH1", LazyProseH1],
["ProseH2", LazyProseH2],
["ProseH3", LazyProseH3],
["ProseH4", LazyProseH4],
["ProseH5", LazyProseH5],
["ProseH6", LazyProseH6],
["ProseHr", LazyProseHr],
["ProseLi", LazyProseLi],
["ProseP", LazyProseP],
["ProsePre", LazyProsePre],
["ProseScript", LazyProseScript],
["ProseStrong", LazyProseStrong],
["ProseTbody", LazyProseTbody],
["ProseTd", LazyProseTd],
["ProseTh", LazyProseTh],
["ProseThead", LazyProseThead],
["ProseTr", LazyProseTr],
["ProseUl", LazyProseUl],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
