import type { NitroFetchOptions } from 'nitropack';
import type { RuntimeConfig } from 'nuxt/schema';
import type { FetchContext } from 'ofetch';
import type { Schema } from 'yup';

export enum Case {
  camel,
  snake,
}

export enum HTTPMethod {
  get = 'get',
  post = 'post',
  put = 'put',
  delete = 'delete',
}

export type RequestParametersType<B, P, Q> = object &
  (B extends undefined ? object : { body: B }) &
  (P extends undefined ? object : { params: P }) &
  (Q extends undefined ? object : { query: Q });

export type Options<
  B = undefined,
  P = undefined,
  Q = undefined,
> = NitroFetchOptions<string> & {
  orderKey?: string;
  methodKey?: string;
  groupKey?: string;
  // TODO: нужно придумать красиво вместо any
  selfInterrupted?: boolean | ((parameters: Options<B, P, Q> | any) => string);
  baseURLMapper?: URLMapItemType[];
  onError?: (error: RequestError | ResponseError) => void;
};

export type PluginOptionsType = {
  logout: () => Promise<void>;
  options: Options;
  orderRequests: Record<string, ((v: unknown) => void)[]>;
  orderHooks: Record<string, (() => void)[]>;
  methodSignals: Record<string, AbortController>;
};

export type NuxtPluginType = {
  $request: PluginOptionsType;
};

type ErrorBodyType = {
  data?: {
    message?: string;
  };
  message?: string;
};

export type URLMapItemType = {
  prefix: RegExp;
  value: string;
};

export type SchemasType<R, B = undefined, P = undefined, Q = undefined> = {
  response?: Schema<R>;
  body?: Schema<B>;
  params?: Schema<P>;
  query?: Schema<Q>;
};

export type PreparedRequestType<
  R,
  B = undefined,
  P extends object | undefined = undefined,
  Q extends Record<string, string> | undefined = undefined,
> = {
  (
    parameters?: RequestParametersType<B, P, Q> | null,
    additionalOptions?: Options,
  ): Promise<R>;
  schemas?: SchemasType<R, B, P, Q>;
  options: Options<B, P, Q>;
  withConfig: (
    config: RuntimeConfig,
  ) => (
    parameters?: RequestParametersType<B, P, Q> | null,
    additionalOptions?: Options,
  ) => Promise<R>;
};

export type HookKey = `${'order' | 'method'}:${string}:${'finish' | 'start'}`;

export enum HTTPError {
  AbortError = 'AbortError',
}

export class FetchError extends Error {
  override name = 'FetchError';
  context?: FetchContext;
  body?: ErrorBodyType;

  constructor(message: string) {
    super(message);
  }

  from(context: FetchContext, body?: ErrorBodyType) {
    this.context = context;
    this.body = body;
    return this;
  }

  with(error?: Error) {
    if (error) {
      this.name = error.name;
      this.cause = error.cause;
      this.stack = error.stack;
    }
    return this;
  }
}

export class RequestError extends FetchError {
  override name = 'RequestError';

  constructor(message: string) {
    super(message);
  }
}

export class ResponseError extends FetchError {
  override name = 'ResponseError';

  constructor(message: string) {
    super(message);
  }
}
