import * as Sentry from '@sentry/vue';

import type { FetchError } from '~/api/utils/types';

function getFetchErrorMessage<T extends FetchError>(
  error: T,
): string | undefined;

function getFetchErrorMessage<T extends FetchError, M extends string>(
  error: T,
  message: M,
): string;

function getFetchErrorMessage<T extends FetchError, M extends string>(
  error: T,
  message?: M,
) {
  return (
    (process.env.NODE_ENV !== 'production'
      ? error.body?.data?.message
      : error.body?.message) || message
  );
}

export { getFetchErrorMessage };

const filterError = (error: Error) =>
  [
    /** Это контролируемая ошибка вызываемая специально. */
    /AbortError/i,

    /**
     * Пользователь может разными путями попасть на несуществующие страницы и это не проблема.
     * Также ошибка может возникать при поиске злоумышленником оставленных ключей и иных файлов -
     * не является проблемой для сайта.
     */
    /Page not found/i,

    /** Meta файлы фреймворка могут меняться после обновления, проблема с ними не стоит внимания. */
    /\/_nuxt\/builds\/meta\/(.+)\.json/,

    /** Файлы фреймворка могут меняться после обновления, проблема с ними не стоит внимания. */
    /Cannot find static asset \/_nuxt\//,

    /** Ошибка связанная с метриками, может вызываться блокировщиками рекламы. */
    /mindbox is not defined/,

    /** Ошибка связанная с метриками, может вызываться блокировщиками рекламы. */
    /VK is not defined/,

    /** Штатная ситуация не требующая внимания, пользователь случайно зашел не туда. */
    /Для оформления заказа необходимо добавить товары в корзину/,

    /** Пользователь может просто опечататься в промокоде - не стоит внимания. */
    /Промокод не применен/,
  ].some((el) => el.test(error.message));

export const logError = (error: unknown, message: string) => {
  const e =
    error instanceof Error
      ? (() => {
          error.message = `${message}: ${error.message}`;
          return error;
        })()
      : new Error(message, { cause: error });

  if (filterError(e)) return;

  fetch('/api/client/error-report', {
    method: 'POST',
    body: `${e.name}: ${e.message}\n${e.stack}`,
  });

  Sentry.captureException(e);

  Promise.resolve().then(() => {
    throw error;
  });
};
