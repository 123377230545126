<script lang="ts" setup>
import type { NuxtError } from '#app';
import background from '~/assets/images/error_background.jpg';
import mobileBackground from '~/assets/images/error_background_mobile.png';

const props = defineProps<{ error: NuxtError }>();

const { t } = useI18n();

if (!import.meta.dev) console.error(props.error);

const errorType = computed(() =>
  [400, 404].includes(props.error.statusCode)
    ? props.error.statusCode
    : 'default',
);

const handleError = () => clearError({ redirect: '/' });

onMounted(() => {
  const error = new Error(props.error.message);
  Object.assign(error, props.error);
  logError(error, 'Переход на страницу ошибки');
});
</script>

<template>
  <main class="errorPage">
    <div class="errorPage__container">
      <NuxtImg
        :src="background"
        :sizes="IMAGE_SIZES.fullscreen"
        format="webp"
        loading="lazy"
        :alt="t('image_alt')"
        class="errorPage__image"
      />
    </div>

    <div class="errorPage__container">
      <NuxtImg
        :src="mobileBackground"
        :sizes="IMAGE_SIZES.fullscreen"
        format="webp"
        loading="lazy"
        :alt="t('image_alt')"
        class="errorPage__image errorPage__image_mobile"
      />
      <div class="errorPage__content">
        <h1 class="errorPage__title">
          {{ t(`error.${errorType}.title`) }}
        </h1>
        <p class="errorPage__description">
          {{ t(`error.${errorType}.description`) }}
        </p>
        <BaseButton @click="handleError" block>
          {{ t(`error.${errorType}.action`) }}
        </BaseButton>
      </div>
    </div>
  </main>
</template>

<i18n>
ru:
  image_alt: Страница ошибки приложения
  error:
    '400':
      title: Неправильные параметры
      description: Скорей всего вы ввели неверный адрес
      action: Вернуться
    '404':
      title: Тут ничего нет
      description: Но мы можем назвать 404 причины перейти на другие страницы.
      action: Вернуться
    default:
      title: Что-то пошло не так
      description: Кажется, у нас тут что-то поломалось и сайт недоступен. Мы уже все чиним, через 15 минут исправим ошибку
      action: Ок

en:
  image_alt: Application error page
  error:
    '400':
      title: Invalid parameters
      description: Most likely you entered the wrong address
      action: Back
    '404':
      title: There's Nothing Here
      description: But we can name 404 reasons to go to other pages.
      action: Back
    default:
      title: Something went wrong
      description: It seems that something broke here and the site is unavailable. We are already fixing everything, we will
        fix the error in 15 minutes
      action: OK
</i18n>

<style lang="scss">
.errorPage {
  display: flex;
  background-color: #672146;
  height: 100vh;
  overflow: hidden;

  &__container {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: relative;

    @media (orientation: portrait) {
      width: 100%;

      &:first-child {
        display: none;
      }
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &_mobile {
      display: none;

      @media (orientation: portrait) {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 464px;
    margin: 0 auto;
    padding: 20px 20px 66px;

    @media (orientation: portrait) {
      padding-top: 164px;
      padding-bottom: 164px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #ffffff;

    @include mq('sm') {
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 12px;
    }
  }

  &__description {
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #ffffff;

    @include mq('sm') {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 40px;
    }
  }
}
</style>
