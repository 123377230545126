
import * as ipxRuntime$qIfKH6U1xwW5EvFokVQ9aCPTC2X_45Mr2kfLh_ViDfJpk from '/app/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_better-sqlite3@11.9.1__ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as ipxRuntime$_624NLa8hixZcZZ_fSJjUQnyj5DveWYRorYs0PtskTek from '/app/image-provider.ts'

export const imageOptions = {
  "screens": {
    "xs": 375,
    "sm": 768,
    "md": 1024,
    "lg": 1240,
    "xl": 1440,
    "xxl": 1920,
    "2xl": 1536,
    "xxxl": 2560
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$qIfKH6U1xwW5EvFokVQ9aCPTC2X_45Mr2kfLh_ViDfJpk, defaults: {"maxAge":31536000} },
  ['ipx']: { provider: ipxRuntime$_624NLa8hixZcZZ_fSJjUQnyj5DveWYRorYs0PtskTek, defaults: undefined }
}
        