
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexvhfhWVotO_456VvDOq_45cYkZMmeSIieYf1jIKy2UpS3yT0Meta } from "/app/pages/auth/index.vue?macro=true";
import { default as change_45passwordTzyC_9WlKDb6qroMbxS_LC_45_Q_6j60EvRxaGrUFd5VYMeta } from "/app/pages/auth/change-password.vue?macro=true";
import { default as authLldLAL6sjaJfinhufTblQ2SvXbV4tXp__siTwYISsSIMeta } from "/app/pages/auth.vue?macro=true";
import { default as indexk_452_45Uu9Ji13zBc4JlfkFE16uSgAeRWdPYL1FQDfm8t8Meta } from "/app/pages/index/blog/index.vue?macro=true";
import { default as _91slug_93jNYBNjNoQ9aOFcp7FWkTwc7IzihG_xNgvf_45e5n27C3sMeta } from "/app/pages/index/blog/[slug].vue?macro=true";
import { default as blogmZW2aZMTx9w6K1QjHtUZKh_LIZxSH_Lyf30O6hTFs9kMeta } from "/app/pages/index/blog.vue?macro=true";
import { default as index74tUkbx7x8b6PFMdA9H6UrdP6t7QDUx2J_2QpXc6e3YMeta } from "/app/pages/index/index.vue?macro=true";
import { default as catalogblPOsFSrD2YJxwriOqd9dJCNPA0A9CDdcZeKYBmF2t0Meta } from "/app/pages/index/catalog.vue?macro=true";
import { default as profilen5WRDfIFaCzlZOvEzgDKYjIRNgew3Uu48Y2WCLIDw2wMeta } from "/app/pages/index/profile.vue?macro=true";
import { default as indexSre5AhmDOQnCIcPfzPs7UcM_457svhen4XNtG1xNZZA9MMeta } from "/app/pages/index/order/index.vue?macro=true";
import { default as faqpcTz4YBizyaJFQ9eiqOdYjaX0Nw9_45L5yv01Ev5cb1hQMeta } from "/app/pages/index/(landing)/faq.vue?macro=true";
import { default as _91slug_93QvAHseTrah4OFCC_kHXrT_JY7FUerPTnNIJwOUwMlqYMeta } from "/app/pages/index/product/[slug].vue?macro=true";
import { default as about6G3Cka7oIVmEBC_45Qjs9C893Vt04Vq5ZNCAPDoWj2JEQMeta } from "/app/pages/index/(landing)/about.vue?macro=true";
import { default as legalq7iTzMMNrgYILCPHghWUOr0AOl6EOTof2IIXKiInbQsMeta } from "/app/pages/index/(landing)/legal.vue?macro=true";
import { default as successfulvKg_45QfTf2CjqhRP_QRDw6kMMXw8jkX4dJUb0jW2EVywMeta } from "/app/pages/index/order/successful.vue?macro=true";
import { default as chatbothrqEdPv_45iq0E3d75ZWmTE86wyvDe38YhRpbDtAR0ZGEMeta } from "/app/pages/index/(landing)/chatbot.vue?macro=true";
import { default as reshapeBo4zCnFtz1Bi0Hk2plpJkflhwd4qay3_45pN9ZrgX5NcYMeta } from "/app/pages/index/(landing)/reshape.vue?macro=true";
import { default as contactsjDpKWk4_45hRRfr3kJlH9K8ow_45jl9t4GI09ViRgBEOyDsMeta } from "/app/pages/index/(landing)/contacts.vue?macro=true";
import { default as bot_45legalvzpTxTnqgEzomLL8xjeWXW3VNzADUTgvvdrHPwaNKBsMeta } from "/app/pages/index/(landing)/bot-legal.vue?macro=true";
import { default as vacancieskRPICX5MmtPHCzEMG3PkDjC4i_454TF0NkHEMcdD6TmSMMeta } from "/app/pages/index/(landing)/vacancies.vue?macro=true";
import { default as sustainableaSN24_45CjanmBIXQ65Zhq0q1nUk_3gJTMRo1aOaE6sxwMeta } from "/app/pages/index/(landing)/sustainable.vue?macro=true";
import { default as change_45refil4UfM33upduj6VuqIH4gdnalCqV2ms_H0mnVG0c21BCwMeta } from "/app/pages/index/(landing)/change-refil.vue?macro=true";
import { default as public_45offerjuDlvsCAMezFSJn0iRJyURtfKTXJV7mJ3MV3BBWzwxUMeta } from "/app/pages/index/(landing)/public-offer.vue?macro=true";
import { default as subscribe_45productctIqsWWE8r_45wjHZf0FouAgcZ_45k7NJmojgMrXQAQiQ9EMeta } from "/app/pages/index/(landing)/subscribe-product.vue?macro=true";
import { default as delivery_45and_45paymentgDbHQP00XvF907ltq__B_45_4594vfmdXVQamyeB7idxTYgMeta } from "/app/pages/index/(landing)/delivery-and-payment.vue?macro=true";
import { default as social_45responsibilityrdLGyaKyjKYZsM7SHJRZka_LLyzNsWLF10C21P_45asBIMeta } from "/app/pages/index/(landing)/social-responsibility.vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as subscribeM2qC5ow0GwrDkg_1P_3CqOUH6cKMyi4PkYF4IhSg0yQMeta } from "/app/pages/subscribe.vue?macro=true";
import { default as component_45stubHVFCSReaaFHn_45bX32IXgQ9MLQfLSjfNvSxG0oKqrp2gMeta } from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.15_better-sqlite3@11.9.1_db0@0.3.1__e97c774b38d887f4f7bb7bde6c74f876/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubHVFCSReaaFHn_45bX32IXgQ9MLQfLSjfNvSxG0oKqrp2g } from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.15_better-sqlite3@11.9.1_db0@0.3.1__e97c774b38d887f4f7bb7bde6c74f876/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: authLldLAL6sjaJfinhufTblQ2SvXbV4tXp__siTwYISsSIMeta?.name,
    path: "/auth",
    component: () => import("/app/pages/auth.vue"),
    children: [
  {
    name: "auth___ru",
    path: "",
    component: () => import("/app/pages/auth/index.vue")
  },
  {
    name: "auth-change-password___ru",
    path: "change-password",
    component: () => import("/app/pages/auth/change-password.vue")
  }
]
  },
  {
    name: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.name,
    path: "/",
    component: () => import("/app/pages/index.vue"),
    children: [
  {
    name: blogmZW2aZMTx9w6K1QjHtUZKh_LIZxSH_Lyf30O6hTFs9kMeta?.name,
    path: "blog",
    component: () => import("/app/pages/index/blog.vue"),
    children: [
  {
    name: "index-blog___ru",
    path: "",
    component: () => import("/app/pages/index/blog/index.vue")
  },
  {
    name: "index-blog-slug___ru",
    path: ":slug()",
    component: () => import("/app/pages/index/blog/[slug].vue")
  }
]
  },
  {
    name: "index___ru",
    path: "",
    component: () => import("/app/pages/index/index.vue")
  },
  {
    name: "index-catalog___ru",
    path: "catalog",
    component: () => import("/app/pages/index/catalog.vue")
  },
  {
    name: "index-profile___ru",
    path: "profile",
    meta: profilen5WRDfIFaCzlZOvEzgDKYjIRNgew3Uu48Y2WCLIDw2wMeta || {},
    component: () => import("/app/pages/index/profile.vue")
  },
  {
    name: "index-order___ru",
    path: "order",
    component: () => import("/app/pages/index/order/index.vue")
  },
  {
    name: "index-faq___ru",
    path: "faq",
    component: () => import("/app/pages/index/(landing)/faq.vue")
  },
  {
    name: "index-product-slug___ru",
    path: "product/:slug()",
    component: () => import("/app/pages/index/product/[slug].vue")
  },
  {
    name: "index-about___ru",
    path: "about",
    component: () => import("/app/pages/index/(landing)/about.vue")
  },
  {
    name: "index-legal___ru",
    path: "legal",
    component: () => import("/app/pages/index/(landing)/legal.vue")
  },
  {
    name: "index-order-successful___ru",
    path: "order/successful",
    component: () => import("/app/pages/index/order/successful.vue")
  },
  {
    name: "index-chatbot___ru",
    path: "chatbot",
    component: () => import("/app/pages/index/(landing)/chatbot.vue")
  },
  {
    name: "index-reshape___ru",
    path: "reshape",
    component: () => import("/app/pages/index/(landing)/reshape.vue")
  },
  {
    name: "index-contacts___ru",
    path: "contacts",
    component: () => import("/app/pages/index/(landing)/contacts.vue")
  },
  {
    name: "index-bot-legal___ru",
    path: "bot-legal",
    component: () => import("/app/pages/index/(landing)/bot-legal.vue")
  },
  {
    name: "index-vacancies___ru",
    path: "vacancies",
    component: () => import("/app/pages/index/(landing)/vacancies.vue")
  },
  {
    name: "index-sustainable___ru",
    path: "sustainable",
    component: () => import("/app/pages/index/(landing)/sustainable.vue")
  },
  {
    name: "index-change-refil___ru",
    path: "change-refil",
    component: () => import("/app/pages/index/(landing)/change-refil.vue")
  },
  {
    name: "index-public-offer___ru",
    path: "public-offer",
    component: () => import("/app/pages/index/(landing)/public-offer.vue")
  },
  {
    name: "index-subscribe-product___ru",
    path: "subscribe-product",
    component: () => import("/app/pages/index/(landing)/subscribe-product.vue")
  },
  {
    name: "index-delivery-and-payment___ru",
    path: "delivery-and-payment",
    component: () => import("/app/pages/index/(landing)/delivery-and-payment.vue")
  },
  {
    name: "index-social-responsibility___ru",
    path: "social-responsibility",
    component: () => import("/app/pages/index/(landing)/social-responsibility.vue")
  }
]
  },
  {
    name: "subscribe___ru",
    path: "/subscribe",
    component: () => import("/app/pages/subscribe.vue")
  },
  {
    name: component_45stubHVFCSReaaFHn_45bX32IXgQ9MLQfLSjfNvSxG0oKqrp2gMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubHVFCSReaaFHn_45bX32IXgQ9MLQfLSjfNvSxG0oKqrp2g
  }
]