import * as Sentry from '@sentry/vue';

const TEMPORARY_FULL_LOGGING = true;

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const router = useRouter();

  Sentry.init({
    app: nuxtApp.vueApp,
    enabled: config.public.siteEnvironment === 'production',
    dsn: config.public.sentryDsn,
    integrations: [
      Sentry.vueIntegration({
        tracingOptions: {
          trackComponents: true,
          hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
        },
      }),
      Sentry.browserSessionIntegration(),
      Sentry.browserTracingIntegration({
        router,
        routeLabel: 'path',
      }),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [config.public.siteUrl],
      }),
    ],
    tracePropagationTargets: [new URL(config.public.siteUrl).host],
    environment: config.public.siteEnvironment,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: TEMPORARY_FULL_LOGGING ? 1 : 0.2,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: TEMPORARY_FULL_LOGGING ? 1 : 0.1,
    replaysOnErrorSampleRate: 1,
  });

  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    Sentry.withScope((scope) => {
      scope.setExtra('context', context);
      logError(error, 'Ошибка Nuxt приложения с контекстом');
    });
  };

  nuxtApp.hook('app:error', (error) => {
    logError(error, 'Ошибка Nuxt приложения');
  });
});
